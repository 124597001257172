html {
    background: #fff;
    max-width: 550px;
    margin: 0 auto;
}
  
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

.app-header {
  background-color: black;
  padding: 17px;
  position: sticky;
  top: 0;
}

.app-footer {
  background-color: black;
  padding: 17px;
  position: sticky;
  bottom: 0;
}
  
.app {
  margin: 0 auto;
  height: 75vh;
  background-color: aquamarine;
  min-height: fit-content;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-button {
	border-radius: 4px;
	padding: 4px;
	animation: colorchange 0.7s infinite;
}

@keyframes colorchange {
	0% {
		background: linear-gradient(0deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	10% {
		background: linear-gradient(25deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	20% {
		background: linear-gradient(50deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	30% {
		background: linear-gradient(75deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	40% {
		background: linear-gradient(100deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	50% {
		background: linear-gradient(140deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	60% {
		background: linear-gradient(180deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	70% {
		background: linear-gradient(220deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	80% {
		background: linear-gradient(260deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	90% {
		background: linear-gradient(300deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
	100% {
		background: linear-gradient(340deg, #e83e8c, #fad648, #fff, #007bff, #44ce7b);
	}
}